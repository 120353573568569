import React, { useState } from 'react';
import { Form, Input, Button, Select, DatePicker, notification } from 'antd';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

function OperationForm() {
    const [form] = Form.useForm();
    const [mtype, setMtype] = useState('');

    const onFinish = async (values) => {
        if (values.Gtime) {
            values.Gtime = values.Gtime.format('YYYY-MM-DDTHH:mm:ssZ');
        }

        const payload = { ...values, Time: new Date().toISOString() };

        try {
            const response = await fetch('https://api.sdjz.wiki/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                notification.success({
                    message: '数据提交成功',
                });
                form.resetFields();
            } else {
                throw new Error('网络响应不是 OK');
            }
        } catch (error) {
            notification.error({
                message: '数据提交失败',
                description: '无法提交运维数据。',
            });
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Form form={form} onFinish={onFinish} layout="vertical" style={{ width: '400px' }}>
                <Form.Item name="Mtype" label="维护类型" rules={[{ required: true, message: '请选择维护类型!' }]}>
                    <Select onChange={value => setMtype(value)}>
                        <Option value="emergency">危急</Option>
                        <Option value="Generalmaintenance">普通维护</Option>
                        <Option value="Bugfix">故障修复</Option>
                        <Option value="notice">通知</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="GA" label="维护人" initialValue="Sudongjiaozi">
                    <Input disabled />
                </Form.Item>
                <Form.Item name="Ginfo" label="维护内容" rules={[{ required: mtype !== 'notice', message: '请输入维护内容!' }]}>
                    <TextArea />
                </Form.Item>
                {mtype === 'notice' && (
                    <Form.Item name="Noifon" label="通知信息" rules={[{ required: true, message: '请输入通知信息!' }]}>
                        <TextArea />
                    </Form.Item>
                )}
                <Form.Item name="Gtime" label="修复时间">
                    <DatePicker showTime />
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default OperationForm;
